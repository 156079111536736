export const sliderItems = [
    {
        id: 1,
        mobileImg: "https://ik.imagekit.io/dnddecpho/haofa/awg2_eJkafEWzd.jpg?updatedAt=1731644741863",
        img:
            "https://ik.imagekit.io/dnddecpho/haofa/awg_MDBWFhPNr.jpg?updatedAt=1731644751097",
        url: "/location",
    },
    {
        id: 2,
        img: "https://ik.imagekit.io/dnddecpho/haofa/Haofa_Desktop_2000_hZXQszPlK.jpg?updatedAt=1719057312663",
        mobileImg:
            "https://ik.imagekit.io/dnddecpho/haofa/Haofa_Mobile_2000_KyOkYZ5-1.jpg?updatedAt=1719057312592",
        url: "https://haofa.my/location",
    },
   
    {
        id: 3,
        img: "https://ik.imagekit.io/dnddecpho/haofa/Haofa_Desktop_Singapore_KWQfimzc-.jpg?updatedAt=1711021084118",
        mobileImg:
            "https://ik.imagekit.io/dnddecpho/haofa/Haofa_Mobile_Singapore_1MiLM0l01_WRmg7GaDS.jpg?updatedAt=1711259199023",
            url: "/location",
    },
    {
        id: 4,
        img: "https://ik.imagekit.io/dnddecpho/haofa/WhatsApp%20Image%202023-10-26%20at%2015.33.44_ff15536c_o8mWSzkiJ.jpg?updatedAt=1698544795850",
        mobileImg:
            "https://ik.imagekit.io/dnddecpho/haofa/LY1_Mobile_M22ZlfTTt.jpg?updatedAt=1711260432810",
        url: "https://haofa.my/product/1988",
    },
    {
        id: 5,
        img: "https://ik.imagekit.io/dnddecpho/haofa/WhatsApp%20Image%202023-10-26%20at%2015.37.34_ff327ca2_WwVZFWjOe.jpg?updatedAt=1698544770621",
        mobileImg:
            "https://ik.imagekit.io/dnddecpho/haofa/LY_Mobile_chB0RBDBR.jpg?updatedAt=1711260432810",
        url: "https://haofa.my/product/2202",
    },

    {
        id: 6,
        img: "https://ik.imagekit.io/dnddecpho/haofa/Haofa_WebsiteMainPage_WebBanner_Oct20234_2Tj0LvpuC.jpg?updatedAt=1697547866974",
        mobileImg:
            "https://ik.imagekit.io/dnddecpho/haofa/Haofa_MobileMainPage_WebBanner_Oct202324_5jBhDpIVg.jpg?updatedAt=1697547980319",
        url: "https://haofa.my/product/2231",
    },
    {
        id: 7,
        img: "https://ik.imagekit.io/dnddecpho/haofa/Haofa_WebsiteMainPage_WebBanner_Oct20232_duSlwcwmT.jpg?updatedAt=1697547867242",
        mobileImg:
            "https://ik.imagekit.io/dnddecpho/haofa/Haofa_MobileMainPage_WebBanner_Oct202322_7mGTFm67v.jpg?updatedAt=1697547980340",
        url: "https://haofa.my/product/2290",
    },
    {
        id: 8,
        img: "https://ik.imagekit.io/dnddecpho/haofa/Haofa_WebsiteMainPage_WebBanner_Oct2023_PReBSuDh-.jpg?updatedAt=1697547867014",
        mobileImg:
            "https://ik.imagekit.io/dnddecpho/haofa/Haofa_MobileMainPage_WebBanner_Oct20232_CW_fVzbbS.jpg?updatedAt=1697547980133",
        url: "https://haofa.my/product/2320",
    },
    {
        id: 9,
        img: "https://ik.imagekit.io/dnddecpho/haofa/Haofa_WebsiteMainPage_WebBanner_Oct20233_5BiDfTdys.jpg?updatedAt=1697547867337",
        mobileImg:
            "https://ik.imagekit.io/dnddecpho/haofa/Haofa_MobileMainPage_WebBanner_Oct202323_YXK1dwe4x.jpg?updatedAt=1697547980253",
        url: "https://haofa.my/product/2311",
    },
    {
        id: 10,
        img: "https://ik.imagekit.io/dnddecpho/haofa/Haofa_WebsiteMainPage_WebBanner_Oct20235_1sGm2XEVP.jpg?updatedAt=1697547867305",
        mobileImg:
            "https://ik.imagekit.io/dnddecpho/haofa/Haofa_MobileMainPage_WebBanner_Oct202325_PZpLyWtq7.jpg?updatedAt=1697547980279",
        url: "https://haofa.my/location",
    },
    

];

import styled from "styled-components";
import { mobile, tablet } from "../responsive";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Newsletter from "../components/Newsletter";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { Mail } from "@material-ui/icons";
import { ReactPixel } from "react-facebook-pixel";

const Container = styled.div`
    background: #1f2f4d;
    display: flex;
    padding: 4rem 100px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    ${mobile({ padding: "4rem 0" })}
    ${tablet({ padding: "4rem 0" })}
`;
const Title = styled.h1`
    font-family: FuturaPT-Demi;
    font-size: 2.5rem;
    padding-bottom: 20px;
    color: white;

    ${mobile({
        display: "block",
        textAlign: "center",
        fontSize: "1.5rem",
        paddingBottom: "4rem",
    })}

    ${tablet({
        display: "block",
        textAlign: "center",
        fontSize: "1.5rem",
        paddingBottom: "4rem",
    })}
`;

const Subtitle = styled.h1`
    font-family: FuturaPT-Demi;
    font-size: 0.8rem;
    text-align: center;
    color: gray;
    ${mobile({
        display: "none",
        textAlign: "center",
        fontSize: "2rem",
        paddingBottom: "4rem",
    })}

    ${tablet({
        display: "none",
        textAlign: "center",
        fontSize: "2rem",
        paddingBottom: "4rem",
    })}
`;

const Address = styled.div`
    font-size: 1.5rem;
    text-align: center;
    color: #ac997b;
    font-weight: 600;
    ${mobile({
        display: "block",
        textAlign: "center",
        fontSize: "1.5rem",
        padding: "2rem 2rem 1rem 1rem",
    })}

    ${tablet({
        display: "block",
        textAlign: "center",
        fontSize: "1.5rem",
        padding: "2rem 2rem 1rem 1rem",
    })}
`;

const LotContainer = styled.div`
    padding: 1rem 0rem;
    ${mobile({
        display: "block",
        padding: "1rem",
        marginBottom: "1rem",
    })}

    ${tablet({
        display: "block",
        padding: "1rem",
        marginBottom: "1rem",
    })}
`;

const Lot = styled.div`
    font-size: 1.2rem;
    color: white;
    text-align: center;
    padding: 1rem 0rem 0rem 0rem;
    ${mobile({
        display: "block",
        fontSize: "1.2rem",
        textAlign: "center",
        padding: "1rem 0rem",
    })}

    ${tablet({
        display: "block",
        fontSize: "1.2rem",
        textAlign: "center",
        padding: "1rem 0rem",
    })}
`;

const Contact = styled.div`
    padding: 1rem 0rem;
    font-size: 1.2rem;
    text-align: center;
    color: white;
    ${mobile({
        textAlign: "center",
        fontSize: "1.2rem",
    })}

    ${tablet({
        textAlign: "center",
        fontSize: "1.2rem",
    })}
`;

const ContactTitle = styled.h1`
    font-size: 1.5rem;
    text-align: center;
    color: #ac997b;
    font-weight: 600;
    padding-bottom: 1rem;
    ${mobile({
        display: "block",
        textAlign: "center",
        fontSize: "1.5rem",
        padding: "4rem 2rem 0 2rem",
    })}

    ${tablet({
        display: "block",
        textAlign: "center",
        fontSize: "1.5rem",
        padding: "4rem 2rem 0 2rem",
    })}
`;

const HaofaJb = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/haofa/Haofa_ToppenLocation_JJRb1BTU7.jpg?updatedAt=1678777428594");
    margin: 3rem;
    background-size: cover;
    background-position: center;
    width: 60vh;
    height: 40vh;
    ${mobile({
        margin: "0rem",
        width: "100%",
    })}

    ${tablet({
        margin: "0rem",
        width: "100%",
    })}
`;

const HaofaOU = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/haofa/haofa-ou_a15jB6pn4.jpg?updatedAt=1713188700687");
    margin: 3rem;
    background-size: cover;
    background-position: center;
    width: 60vh;
    height: 40vh;
    ${mobile({
        margin: "0rem",
        width: "100%",
    })}

    ${tablet({
        margin: "0rem",
        width: "100%",
    })}
`;

const HaofaVelocity = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/haofa/haofavelocity_TAPJfGGt0.jpeg?updatedAt=1680712228102");
    margin: 3rem;
    background-size: cover;
    background-position: center;
    width: 60vh;
    height: 40vh;
    ${mobile({
        margin: "0rem",
        width: "100%",
    })}

    ${tablet({
        margin: "0rem",
        width: "100%",
    })}
`;

const AWG = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/haofa/ssawg_tjee4BvcH.jpg?updatedAt=1731645525919");
    margin: 3rem;
    background-size: cover;
    background-position: center;
    width: 60vh;
    height: 40vh;
    ${mobile({
        margin: "0rem",
        width: "100%",
    })}

    ${tablet({
        margin: "0rem",
        width: "100%",
    })}
`;


const SG = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/haofa/haofa-sg_nQfHgRi64.jpg?updatedAt=1706978928858");
    margin: 3rem;
    background-size: cover;
    background-position: center;
    width: 60vh;
    height: 40vh;
    ${mobile({
        margin: "0rem",
        width: "100%",
    })}

    ${tablet({
        margin: "0rem",
        width: "100%",
    })}
`;

const DekairosSubang = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/haofa/Dekairos-subang(1)__3GrfRPPV.jpg?updatedAt=1714992797814");
    margin: 3rem;
    background-size: cover;
    background-position: center;
    width: 60vh;
    height: 40vh;
    ${mobile({
        margin: "0rem",
        width: "100%",
    })}

    ${tablet({
        margin: "0rem",
        width: "100%",
    })}
`;

const Boyi = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/Haofa/boyi_t-JZLTd25.jpeg?updatedAt=1723018835836");
    margin: 3rem;
    background-size: cover;
    background-position: center;
    width: 60vh;
    height: 40vh;
    ${mobile({
        margin: "0rem",
        width: "100%",
    })}

    ${tablet({
        margin: "0rem",
        width: "100%",
    })}
`;

const AboutContainer = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    margin-bottom: 3rem;
    ${mobile({ display: "block", margin: "0" })}

    ${tablet({ display: "block", margin: "0" })}
`;

const ContactContainer = styled.div`
    display: grid;
    margin-bottom: 3rem;
    ${mobile({ display: "block", margin: "0" })}

    ${tablet({ display: "block", margin: "0" })}
`;

const FlexContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Button = styled.button`
    width: 100%;
    border-radius: 35px;
    border-color: transparent;
    padding: 15px 40px;
    background-color: #ac997b;
    color: white;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 800;
    &:hover {
        background-color: black;
        border: 2px solid transparent;
    }
    ${mobile({ width: "inherit", marginTop: "2rem" })}
    ${tablet({ width: "inherit", marginTop: "2rem" })}
`;

const Location = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        const load = async () => {
            try {
                ReactPixel.init(532645418897855, null, {
                    autoConfig: true,
                    debug: true,
                });
                ReactPixel.track("FindLocation");
            } catch (err) {
                console.log(err);
            }
        };
        load();
    }, []);
    return (
        <>
            <Navbar />
            <Container>
                <Title>STORE LOCATION</Title>
                <AboutContainer>
                <div>
                        <HaofaOU></HaofaOU>
                        <Subtitle>Official Outlet</Subtitle>
                        <LotContainer>
                            {" "}
                            <Address>1 Utama Shopping Centre</Address>
                            <Lot>Lot FK1C, First Floor</Lot>
                            <Lot>03-6242 8168</Lot>
                        </LotContainer>
                    </div>
                    <div>
                        <HaofaVelocity></HaofaVelocity>
                        <Subtitle>Official Outlet</Subtitle>
                        <LotContainer>
                            {" "}
                            <Address>Sunway Velocity Mall Building</Address>
                            <Lot>No. 01-75, Floor 1</Lot>
                            <Lot>03-9226 8661</Lot>
                        </LotContainer>
                    </div>
                    <div>
                        <HaofaJb></HaofaJb>
                        <Subtitle>Official Outlet</Subtitle>
                        <LotContainer>
                            {" "}
                            <Address>
                                HAOFA Toppen Shopping Mall, Johor Bahru
                            </Address>
                            <Lot>L1-95, Level 1</Lot>
                            <Lot>011-5858 5515</Lot>
                        </LotContainer>
                    </div>
                    <div>
                        <Boyi></Boyi>
                        <Subtitle>Authorized Dealer</Subtitle>

                        <LotContainer>
                            {" "}
                            <Address>Boyi & Co</Address>
                            <Lot>24, Jalan SS 21/1</Lot>
                            <Lot>018-264 1414</Lot>
                        </LotContainer>
                    </div>
                    <div>
                        <AWG></AWG>
                        <Subtitle>Authorized Dealer</Subtitle>

                        <LotContainer>
                            {" "}
                            <Address>AWG Mid Valley</Address>
                            <Lot>Unit 017 (Ground Floor)</Lot>
                            <Lot>03-2284 4228</Lot>
                        </LotContainer>
                    </div>
                    <div>
                        <DekairosSubang></DekairosSubang>
                        <Subtitle>Authorized Dealer</Subtitle>

                        <LotContainer>
                            {" "}
                            <Address>Dekairos Empire Subang</Address>
                            <Lot>G-18, Ground Floor</Lot>
                            <Lot>012-553 9388</Lot>
                        </LotContainer>
                    </div>
                    <div>
                        <SG></SG>
                        <Subtitle>Authorized Dealer</Subtitle>

                        <LotContainer>
                            {" "}
                            <Address>HAOFA Singapore (SG) </Address>
                            <Lot>304 Orchard Road, #B1-102 Lucky Plaza,</Lot>
                            <Lot>Singapore 238863</Lot>
                            <Lot>+65-976-HAOFA</Lot>
                        </LotContainer>
                    </div>
                </AboutContainer>
                <ContactContainer>
                    <div>
                        <ContactTitle>HQ Contact Details</ContactTitle>
                        <FlexContainer>
                            {" "}
                            <Mail
                                style={{
                                    marginRight: "1rem",
                                    color: "white",
                                }}
                            ></Mail>{" "}
                            <Contact>support@haofa.my</Contact>
                        </FlexContainer>
                    </div>
                </ContactContainer>
                <Link
                    style={{ textDecoration: "none", color: "black" }}
                    to={"/contact"}
                >
                    <Button>CONTACT US</Button>
                </Link>
            </Container>
            <Newsletter></Newsletter>
            <Footer></Footer>
        </>
    );
};

export default Location;

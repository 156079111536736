import styled from "styled-components";
import { mobile, tablet } from "../responsive";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import "../assets/styles/swiper.css";
import { useState } from "react";
import waze from "../assets/waze.svg";
import maps from "../assets/maps.svg";

const Container = styled.div`
    background: white;
    display: grid;
    align-items: center;
    grid-template-columns: 50% 50%;
    flex-direction: row;
    ${mobile({ padding: "0", display: "block" })}
    ${tablet({ padding: "0", display: "block" })}
`;
const Title = styled.h1`
    letter-spacing: 0.1rem;
    padding: 5rem 2rem 2rem 2rem;
    font-family: FuturaPT-Bold;
    font-size: 3rem;
    color: #ac997b;
    text-align: center;
    margin: 1.5rem 0rem 1rem 0;
    ${mobile({
        display: "block",
        textAlign: "center",
        fontSize: "1.8rem",
        padding: "2rem 2rem 2rem 2rem",
    })}
    ${tablet({
        display: "block",
        textAlign: "center",
        fontSize: "1.8rem",
        padding: "2rem 2rem 2rem 2rem",
    })}
`;

const LeftA = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const VelocityImage = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/haofa/haofavelocity_TAPJfGGt0_NllyST1q6_LrW6RFOuQ.webp?updatedAt=1705771648324");
    background-size: cover;
    height: 38vh;
    background-position: center;
    ${mobile({
        margin: "0rem",
    })}
    ${tablet({
        margin: "0rem",
    })}
`;

const OUImage = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/haofa/haofa-ou_a15jB6pn4.jpg?updatedAt=1713188700687");
    background-size: cover;
    height: 38vh;
    background-position: center;
    ${mobile({
        margin: "0rem",
    })}
    ${tablet({
        margin: "0rem",
    })}
`;

const ToppenImage = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/haofa/Haofa_ToppenLocation_JJRb1BTU7_j-qpsTMIt_FOr3cpyLp.webp?updatedAt=1705771648282");
    background-size: cover;
    height: 38vh;
    background-position: center;
    ${mobile({
        margin: "0rem",
    })}
    ${tablet({
        margin: "0rem",
    })}
`;

const AWGImage = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/haofa/ssawg_tjee4BvcH.jpg?updatedAt=1731645525919");
    background-size: cover;
    height: 38vh;
    background-position: center;
    ${mobile({
        margin: "0rem",
    })}
    ${tablet({
        margin: "0rem",
    })}
`;

const BoyiImage = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/Haofa/boyi_t-JZLTd25.jpeg?updatedAt=1723018835836");
    background-size: cover;
    height: 38vh;
    background-position: center;
    ${mobile({
        margin: "0rem",
    })}
    ${tablet({
        margin: "0rem",
    })}
`;

const StarhillImage = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/haofa/bigbangshop_uLymlj9NM_yKWgdjWob_8ZMNpdcIn.webp?updatedAt=1705771648368");
    background-size: cover;
    height: 38vh;
    background-position: center;
    ${mobile({
        margin: "0rem",
    })}
    ${tablet({
        margin: "0rem",
    })}
`;

const DekairosSubangImage = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/haofa/Dekairos-subang(1)__3GrfRPPV.jpg?updatedAt=1714992797814");
    background-size: cover;
    height: 38vh;
    background-position: center;
    ${mobile({
        margin: "0rem",
    })}
    ${tablet({
        margin: "0rem",
    })}
`;

const SGImage = styled.div`
    background: url("https://ik.imagekit.io/dnddecpho/haofa/haofa-sg_nQfHgRi64.jpg?updatedAt=1706978928858");
    background-size: cover;
    height: 38vh;
    background-position: center;
    ${mobile({
        margin: "0rem",
    })}
    ${tablet({
        margin: "0rem",
    })}
`;

const OutletContainer = styled.div`
    display: block;
    max-height: 80vh;
    overflow-x: hidden;
    overflow-y: scroll;
    ${mobile({
        maxHeight: "1000vh",
    })}
    ${tablet({
        maxHeight: "1000vh",
    })}
`;

const ItemContainer = styled.div`
    background: #f5fafd;
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: 50% 50%;
`;

const Content = styled.div`
    padding: 0 2rem;
    font-size: 1.2rem;
    line-height: 1.8;
    ${mobile({
        fontSize: "0.9rem",
        padding: "0 1rem",
    })}
    ${tablet({
        fontSize: "1rem",
        padding: "0 1rem",
    })}
`;

const BoldContent = styled.div`
    font-family: "FuturaPT-Bold";
    padding: 0 2rem;
    font-size: 1.2rem;
    line-height: 1.8;
    font-weight: 900;
    ${mobile({
        fontSize: "0.9rem",
        padding: "0 1rem",
    })}
    ${tablet({
        fontSize: "1rem",
        padding: "0 1rem",
    })}
`;

const SameRowContainer = styled.div`
    display: flex;
    padding-top: 10px;
`;

const Icons = styled.a`
    padding: 0 2rem;
    padding-right: 0rem;
    font-size: 1.2rem;
    line-height: 1.8;
    cursor: pointer;
    text-decoration: none;
    color: black !important;
    ${mobile({
        fontSize: "1rem",
        padding: "0 1rem",
    })}

    ${tablet({
        fontSize: "1rem",
        padding: "0 1rem",
    })}
`;

const Payment = styled.img`
    height: 3vh;
`;

const Outlet = () => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    });

    const [center, setCenter] = useState({
        lat: 3.1286070984292844,
        lng: 101.72601307040058,
    });
    const [zoom, setZoom] = useState(12);

    return (
        <>
            <Title>STORE LOCATIONS</Title>
            <Container>
                <LeftA>
                    <OutletContainer>
                        <ItemContainer>
                            <OUImage></OUImage>
                            <LeftA>
                                <BoldContent>HAOFA One Utama</BoldContent>
                                <Content>Lot FK1C, First Floor,</Content>
                                <Content>Contact number: 03-6242 8168</Content>
                                <Content>Business hours: 10am - 10pm</Content>
                                <SameRowContainer>
                                    {" "}
                                    <Icons
                                        onClick={() => {
                                            setCenter(
                                                {
                                                    lat: 3.1480679,
                                                    lng: 101.6164417,
                                                },
                                                setZoom(17)
                                            );
                                        }}
                                    >
                                        <Payment src={maps}></Payment>
                                    </Icons>
                                    <Icons
                                        href="https://www.waze.com/en-GB/live-map/directions/my/selangor/pj/1-utama-shopping-centre?to=place.ChIJL-Qvn9JOzDERBLmeTTrrgf0"
                                        target="blank"
                                    >
                                        <Payment src={waze}></Payment>
                                    </Icons>
                                </SameRowContainer>
                            </LeftA>
                        </ItemContainer>
                        <ItemContainer>
                            <VelocityImage></VelocityImage>
                            <LeftA>
                                <BoldContent>HAOFA Sunway Velocity</BoldContent>
                                <Content>No. 01-75, Floor 1</Content>
                                <Content>Contact number: 03-9226 8661</Content>
                                <Content>Business hours: 10am - 10pm</Content>
                                <SameRowContainer>
                                    {" "}
                                    <Icons
                                        onClick={() => {
                                            setCenter(
                                                {
                                                    lat: 3.1286070984292844,
                                                    lng: 101.72601307040058,
                                                },
                                                setZoom(17)
                                            );
                                        }}
                                    >
                                        <Payment src={maps}></Payment>
                                    </Icons>
                                    <Icons
                                        href="https://www.waze.com/en-GB/live-map/directions/sunway-velocity-mall-lingkaran-sv-kuala-lumpur?to=place.w.66650143.666632505.9244242"
                                        target="blank"
                                    >
                                        <Payment src={waze}></Payment>
                                    </Icons>
                                </SameRowContainer>
                            </LeftA>
                        </ItemContainer>
                        <ItemContainer>
                            <ToppenImage></ToppenImage>
                            <LeftA>
                                <BoldContent>HAOFA JB Toppen Mall</BoldContent>
                                <Content>L1-95, Level 1</Content>
                                <Content>Contact number: 011-5858 5515</Content>
                                <Content>Business hours: 10am - 10pm</Content>
                                <SameRowContainer>
                                    {" "}
                                    <Icons
                                        onClick={() => {
                                            setCenter(
                                                {
                                                    lat: 1.5528705461571326,
                                                    lng: 103.79860922327912,
                                                },
                                                setZoom(17)
                                            );
                                        }}
                                    >
                                        <Payment src={maps}></Payment>
                                    </Icons>
                                    <Icons
                                        href="https://www.waze.com/live-map/directions/toppen-shopping-centre-persiaran-bandar-desa-tebrau-johor-bahru?to=place.w.68026384.680263835.21423244"
                                        target="blank"
                                    >
                                        <Payment src={waze}></Payment>
                                    </Icons>
                                </SameRowContainer>
                            </LeftA>
                        </ItemContainer>
                        <ItemContainer>
                            <AWGImage></AWGImage>
                            <LeftA>
                                <BoldContent>
                                    AWG Mid Valley
                                </BoldContent>
                                <Content>Unit 017 (Ground Floor)</Content>
                                <Content>
                                    Contact number: 03-2284 4228
                                </Content>
                                <Content>Business hours: 10am - 10pm</Content>

                                <SameRowContainer>
                                    {" "}
                                    <Icons
                                        onClick={() => {
                                            setCenter(
                                                {
                                                    lat: 3.1175628,
                                                    lng: 101.6748324,
                                                },
                                                setZoom(17)
                                            );
                                        }}
                                    >
                                        <Payment src={maps}></Payment>
                                    </Icons>
                                    <Icons
                                        href="https://www.waze.com/en-GB/live-map/directions/mid-valley-megamall-lingkaran-syed-putra-1-kuala-lumpur?to=place.w.66650143.666370360.358921"
                                        target="blank"
                                    >
                                        <Payment src={waze}></Payment>
                                    </Icons>
                                </SameRowContainer>
                            </LeftA>
                        </ItemContainer>
                        <ItemContainer>
                            <BoyiImage></BoyiImage>
                            <LeftA>
                                <BoldContent>
                                    Boyi & Co (Damansara Uptown)
                                </BoldContent>
                                <Content>24, Jalan SS 21/1</Content>
                                <Content>
                                    Contact number: 018 - 264 1313
                                </Content>
                                <Content>Business hours: 10am - 10pm</Content>

                                <SameRowContainer>
                                    {" "}
                                    <Icons
                                        onClick={() => {
                                            setCenter(
                                                {
                                                    lat: 3.1341785,
                                                    lng: 101.6206124,
                                                },
                                                setZoom(17)
                                            );
                                        }}
                                    >
                                        <Payment src={maps}></Payment>
                                    </Icons>
                                    <Icons
                                        href="https://www.waze.com/en-GB/live-map/directions/my/selangor/pj/boyiandco.%E4%BA%8C%E6%89%8B%E5%90%8D%E7%89%8C%E4%B8%93%E5%8D%96%E5%BA%97-kl?to=place.ChIJ6VTSiN1JzDERLDAogvG_fHE"
                                        target="blank"
                                    >
                                        <Payment src={waze}></Payment>
                                    </Icons>
                                </SameRowContainer>
                            </LeftA>
                        </ItemContainer>
                        {/* <ItemContainer>
                            <StarhillImage></StarhillImage>
                            <LeftA>
                                <BoldContent>
                                    The BigBang Shop Starhill
                                </BoldContent>
                                <Content>L153, Level 1, The Starhill</Content>
                                <Content>Contact number: 012-678 1057</Content>
                                <Content>Business hours: 10am - 10pm</Content>

                                <SameRowContainer>
                                    {" "}
                                    <Icons
                                        onClick={() => {
                                            setCenter(
                                                {
                                                    lat: 3.147366,
                                                    lng: 101.713963,
                                                },
                                                setZoom(17)
                                            );
                                        }}
                                    >
                                        <Payment src={maps}></Payment>
                                    </Icons>
                                    <Icons
                                        href="https://www.waze.com/en-GB/live-map/directions/the-starhill-jalan-bukit-bintang-181-kuala-lumpur?to=place.w.66650143.666566971.1405597"
                                        target="blank"
                                    >
                                        <Payment src={waze}></Payment>
                                    </Icons>
                                </SameRowContainer>
                            </LeftA>
                        </ItemContainer> */}
                        <ItemContainer>
                            <DekairosSubangImage></DekairosSubangImage>
                            <LeftA>
                                <BoldContent>
                                    Dekairos Empire Shopping Gallery
                                </BoldContent>
                                <Content>G-18, Ground Floor</Content>
                                <Content>Contact number: 012-553 9388</Content>
                                <Content>Business hours: 10am - 10pm</Content>

                                <SameRowContainer>
                                    {" "}
                                    <Icons
                                        onClick={() => {
                                            setCenter(
                                                {
                                                    lat: 3.0818917483085606,
                                                    lng: 101.58242982705308,
                                                },
                                                setZoom(17)
                                            );
                                        }}
                                    >
                                        <Payment src={maps}></Payment>
                                    </Icons>
                                    <Icons
                                        href="https://www.waze.com/en-GB/live-map/directions/my/selangor/subang-jaya/nu-empire-shopping-gallery?to=place.ChIJ1dO4iFpMzDERNDCXYKfbsSg"
                                        target="blank"
                                    >
                                        <Payment src={waze}></Payment>
                                    </Icons>
                                </SameRowContainer>
                            </LeftA>
                        </ItemContainer>
                        <ItemContainer>
                            <SGImage></SGImage>
                            <LeftA>
                                <BoldContent>HAOFA Singapore (SG) </BoldContent>
                                <Content>
                                    {" "}
                                    #B1-102 Lucky Plaza, 304 Orchard Road,
                                </Content>
                                <Content>Singapore 238863</Content>
                                <Content>
                                    Contact number: +65-976-HAOFA{" "}
                                </Content>
                                <Content>Business hours: 10am - 10pm</Content>

                                <SameRowContainer>
                                    {" "}
                                    <Icons
                                        onClick={() => {
                                            setCenter(
                                                {
                                                    lat: 1.3045795,
                                                    lng: 103.8340621,
                                                },
                                                setZoom(17)
                                            );
                                        }}
                                    >
                                        <Payment src={maps}></Payment>
                                    </Icons>
                                    <Icons
                                        href="https://www.waze.com/live-map/directions/sg/singapore/lucky-plaza?to=place.ChIJ6xFibZIZ2jERlMTu3Bo6a9c"
                                        target="blank"
                                    >
                                        <Payment src={waze}></Payment>
                                    </Icons>
                                </SameRowContainer>
                            </LeftA>
                        </ItemContainer>
                    </OutletContainer>
                </LeftA>
                {isLoaded ? (
                    <GoogleMap
                        zoom={zoom}
                        center={center}
                        mapContainerClassName="mapContainer"
                    >
                        <Marker
                            position={{
                                lat: 3.1286070984292844,
                                lng: 101.72601307040058,
                            }}
                            onClick={() => {
                                setCenter(
                                    {
                                        lat: 3.1286070984292844,
                                        lng: 101.72601307040058,
                                    },
                                    setZoom(17)
                                );
                            }}
                        />
                          <Marker
                            position={{
                                lat: 3.1175628,
                                lng: 101.6748324,
                            }}
                            onClick={() => {
                                setCenter(
                                    {
                                        lat: 3.1286070984292844,
                                        lng: 101.72601307040058,
                                    },
                                    setZoom(17)
                                );
                            }}
                        />
                          
                        <Marker
                            position={{
                                lat: 1.5528705461571326,
                                lng: 103.79860922327912,
                            }}
                            onClick={() => {
                                setCenter(
                                    {
                                        lat: 1.5528705461571326,
                                        lng: 103.79860922327912,
                                    },
                                    setZoom(17)
                                );
                            }}
                        />
                        <Marker
                            position={{
                                lat: 3.1341785,
                                lng: 101.6206124,
                            }}
                            onClick={() => {
                                setCenter(
                                    {
                                        lat: 3.1341785,
                                        lng: 101.6206124,
                                    },
                                    setZoom(17)
                                );
                            }}
                        />

                        <Marker
                            position={{
                                lat: 3.0818917483085606,
                                lng: 101.58242982705308,
                            }}
                            onClick={() => {
                                setCenter(
                                    {
                                        lat: 3.0818917483085606,
                                        lng: 101.58242982705308,
                                    },
                                    setZoom(17)
                                );
                            }}
                        />
                        <Marker
                            position={{
                                lat: 3.045819004263376,
                                lng: 101.61833691837514,
                            }}
                            onClick={() => {
                                setCenter(
                                    {
                                        lat: 3.045819004263376,
                                        lng: 101.61833691837514,
                                    },
                                    setZoom(17)
                                );
                            }}
                        />
                        {/* <Marker
                            position={{
                                lat: 3.147366,
                                lng: 101.713963,
                            }}
                            onClick={() => {
                                setCenter(
                                    {
                                        lat: 3.147366,
                                        lng: 101.713963,
                                    },
                                    setZoom(17)
                                );
                            }}
                        /> */}
                        <Marker
                            position={{
                                lat: 1.3045795,
                                lng: 103.8340621,
                            }}
                            onClick={() => {
                                setCenter(
                                    {
                                        lat: 1.3045795,
                                        lng: 103.8340621,
                                    },
                                    setZoom(17)
                                );
                            }}
                        />
                    </GoogleMap>
                ) : (
                    "loading.."
                )}
            </Container>
        </>
    );
};

export default Outlet;
